<template>
<v-layout>
<v-list-item @click="$router.push('/app/plans/view/'+plan._id)" three-line  class="bottom-border">
    <v-list-item-content>
        <v-layout>
            <v-flex xs8 mx-3 my-4>
                <v-list-item-title class="subtitle-1">{{plan.name}}</v-list-item-title>
                <v-list-item-title class="caption">Validity : {{plan.validity}} days</v-list-item-title>
            </v-flex>
            <v-flex xs2 my-4>
                <v-list-item-subtitle class="body-1">₹ {{plan.cost}}/-</v-list-item-subtitle>
                <v-list-item-subtitle class="caption">₹ {{plan.commission}}</v-list-item-subtitle>
            </v-flex>
            <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <action-button key="Edit" icon="mdi-pencil" :show="isAllowed('editPlan')" @click="$router.push('/app/plans/'+plan._id)"></action-button>
                    </v-list-item>
                    <v-list-item>
                        <action-button key="Delete" icon="mdi-delete" :show="isAllowed('deletePlan')" @click="$emit('delete',plan._id)"></action-button>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-layout>
    </v-list-item-content>
</v-list-item>
</v-layout>
</template>

<script>
import moment from 'moment'
export default {
    props: ["icons", "plan"],
    methods: {
        completeAction(icon, id) {
            if (icon.name == "mdi-delete")
                this.$emit('delete', id)
            else
                this.performAction(icon, id)
        }
    },
};
</script>

<style scoped>
.bottom-border{
    border-bottom: 1px solid gray;
}
</style>
