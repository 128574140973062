<template>
    <div>
        <div class="display-block">
            <alert-message v-if="msg" :error="msg"></alert-message>
            <page-header v-model="search" title="Plans" permission="createPlan"
                @click="navigateTo('/app/plans/0')"></page-header>
        </div>
        <div>
            <v-data-table :headers="headers" :items="items" class="elevation-1" :search="search" hide-default-footer
                :items-per-page="items.length">
                <template v-slot:item.allowedModules="{ item }">
                    <modules-list :allowedModules="item.allowedModules"></modules-list>
                </template>
                <template v-slot:item.amount="{ item }">
                    Rs. {{ item.amount }}/-
                </template>
                <template v-slot:item.frequency="{ item }">
                    Billed Every {{ item.interval }} {{ item.frequency.toLowerCase().replace('ly', '') }}
                </template>
                <template v-slot:item.active="{ item }">
                    <v-chip dark label small :style="`background-color:${item.active ? 'green' : 'grey'}`">{{
                        item.active ?
                            'Active' :
                            'Disabled'
                        }}</v-chip>
                </template>
                <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                    <template v-for="icon in icons">
                        <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)"
                            @click="icon.name == 'mdi-delete' ? deletePlan(item._id) : $router.push(icon.path + '' + item._id)"></action-button>
                    </template>
                </template>
                <template v-slot:item="{ item }" v-if="$vuetify.breakpoint.xsOnly">
                    <plan-card :plan="item" :icons="icons" @delete="removeItem"></plan-card>
                </template>
            </v-data-table>
            <pagination :api="apiCall" v-model="items"></pagination>
        </div>
        <alert-message v-if="msg" :error="msg"></alert-message>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import PlanCard from '@/components/PlanResponsiveCard.vue'
import ModulesList from '../../../components/ModulesList.vue';
export default {
    components: {
        ActionButton,
        PlanCard,
        ModulesList
    },
    data() {
        return {
            search: "",
            headers: [{
                text: "Name",
                value: "title"
            },
            {
                text: "Price",
                value: 'amount',
                align: 'right'
            },
            {
                text: "Frequency",
                value: 'frequency',
            },
            {
                text: "Modules",
                value: 'allowedModules',
            },
            {
                text: "Status",
                value: 'active',
            },
            {
                text: 'Actions',
                value: 'action',
                align: 'right'
            }
            ],
            search: '',
            items: [],
            icons: [{
                name: "mdi-eye",
                path: "/app/plans/view/",
                permission: "viewPlan"
            }, {
                name: "mdi-pencil",
                path: "/app/plans/",
                permission: "editPlan"
            },
            {
                name: "mdi-delete",
                permission: "deletePlan"
            }
            ],
            apiCall: appConstants.PLANS_API
        };
    },
    methods: {
        async deletePlan(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Plan?", appConstants.PLANS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        removeItem(id) {
            this.deletePlan(id)
        }
    }
};
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
